<template>
  <div class="not-found">
    <section class="overflow-hidden">
    <div class="container container-404">
      <div class="error-content text-center">
        <h2 class="mb-2 font-size-80">Oops! Page not found</h2>

        <v-img src="../../assets/404.png" max-width="350" alt="" class="mb-4 w-75 mx-auto"></v-img>

        <h3 class="m-0">
          We are sorry, but the page you requested was not found.
        </h3>

        <div class="error-btn mt-4">
          <v-btn
            color="header"
            class="elevation-0 white--text"
            @click="goToDashBoard"
          >
          GO TO {{path}}
          </v-btn>
        </div>

      </div>
    </div>
  </section>
  </div>
</template>
<script>
export default {
  data: () => ({
    path: '',
    base_url: null,
  }),
  mounted() {
    this.base_url = this.$store.state.user.BaseUrl;
    this.path = this.base_url ? 'Dashboard Page' : 'Login Page'
  },
  methods: {
    goToDashBoard(){
        this.$router.replace('/')
        this.$router.replace(this.base_url + '/dashboard')
    }
  }
}
</script>
<style>
  .paragraph-text {
    font-size: 18px;
  }
  .container-404 {
    background-color: white;
    border-radius: 10px;
    padding: 40px;
    margin-top: 50px;
  }
</style>